import * as React from "react"
import styled from "styled-components";
import Shape1 from "../images/shape1.svg";
import Shape2 from "../images/shape2.svg";
import Shape3 from "../images/shape3.svg";
import Shape4 from "../images/shape4.svg";
// import Shape5 from "../images/shape5.svg";
// import Shape6 from "../images/shape6.svg";
// import Shape7 from "../images/shape7.svg";
// import Shape8 from "../images/shape8.svg";
// import Shape9 from "../images/shape9.svg";
import Shape10 from "../images/shape10.svg";

const Sectionaholder = styled.div`
.shapes_a{
background-image: url('${Shape2}'); 
margin-top:520px; 
margin-left:900px; 
}
.shapes_b{
	background-image: url('${Shape4}'); margin-top:444px; margin-left:816px; 
}
.shapes_c{
	background-image: url('${Shape10}'); margin-top:880px; margin-left:1820px;
}
.shapes_d{
	background-image: url('${Shape1}'); margin-top:70px; margin-left:158px;
}
.shapes_e{
	background-image: url('${Shape3}'); margin-top:165px; margin-left:615px;
}
`;

const Classescontent = () => {
  return (
  <Sectionaholder>
<section className="section-classes">
		<div className="shapeshifter">
<div className="shapes shapes_a"></div>
<div className="shapes shapes_b"></div>
<div className="shapes shapes_c"></div>
<div className="shapes shapes_d"></div>
<div className="shapes shapes_e"></div></div>	
<div className="container "> 
		<div className="columns is-marginless is-centered">

		<div className="column is-two-thirds"> 
				<h1 className="anote">Reform Pilates, Cork</h1>
				<h2 className="descriptionzone">Mat, Barre and Reformer pilates classes in Cork City</h2>

				<p className="genpee maintext">Described as the most effective form of Pilates by many, the beauty of <strong>Reformer</strong> is that it provides a total body workout, but through a low-risk intensity session.
</p>
<p className="genpee maintext">
Reformer Pilates is suitable for anyone in Cork that is keen to start moving again. Have you an injury that you need to be mindful of? Are you trying to regain fitness after giving birth? Do you need to improve your core strength for another sport? Reformer Pilates is a suitable fitness class for everyone - from a person that cannot do a full gym session or those looking to recover from involvement in high-endurance activities.
</p>
<p className="genpee maintext">
Our classes are smaller in number to ensure individual support for every client, with 1-2-1 sessions available at our Cork city studio to those with more significant requirements.</p>
				<p className="signature">Join us in our new studio!</p>

		<div className="columns is-marginless">

			<div className="column is-two-fifths is-offset-one-fifth"> 
				<div className="picholder">
				<figure className="yellowbox"></figure>
				<figure className="picbox"></figure>
			</div>
			</div>
		</div>
		</div>

		</div>

	</div>
</section>
    </Sectionaholder>
  );
}

export default Classescontent